@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap");

.contactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 110vh;
  background-color: #00796b;
  color: #ffffff;
  text-align: center;

  .contactBox {
    background-image: url("../images/backdrop.png"); /* Path to your background image */
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 1240px;
    height: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #ffffff;

    .letTalk {
      background-image: url("../images/Link.svg");
      width: 200px;
      height: 200px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      background-size: cover;
      background-position: center;

      // &:hover {
      //   width: 160px;
      //   height: 160px;
      // }
    }

    .email {
      font-size: 28px;
      margin: 10px 0;
      cursor: pointer;
      font-weight: 700;
      color: #000;
    }

    .email:hover {
      text-decoration: underline;
    }

    .socialLinks {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 15px;

      div {
        display: flex;
        gap: 8px;
        cursor: pointer;
        align-items: center;
      }

      a {
        color: #000;
        text-decoration: none;
        font-weight: 500;
        transition: color 0.3s;

        &:hover {
          color: #004d40;
        }
      }
    }
  }

  .nav {
    bottom: 0;
    display: flex;
    gap: 40px;
    width: 100%;
    height: 200px;
    background: #000;
    align-items: center;
    justify-content: center;

    a {
      color: #afa18f;
      text-decoration: none;
      font-size: 18px;
      transition: color 0.3s;

      &:hover {
        color: #00a699;
      }
    }
  }
}

.scrollContainer {
  width: 100%;
  overflow: hidden;
  background-color: #000;
  color: #afa18f;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  height: 150px;

  .scrollText {
    display: inline-block;
    white-space: nowrap;
    animation: scroll 15s linear infinite;

    span {
      display: inline-block;
      padding-right: 100%;
      font-size: 150px;
      font-weight: 700;
      font-family: "Playfair Display", serif;
    }
  }
}

@keyframes scroll {
  from {
    transform: translateX(100%);
    opacity: 0.55;
  }
  to {
    transform: translateX(-100%);
    opacity: 1;
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .contactBox {
    width: 90%;
    height: auto;

    .letTalk {
      width: 140px;
      height: 140px;
    }

    .email {
      font-size: 24px;
    }

    .socialLinks {
      gap: 15px;

      div {
        gap: 6px;
      }

      a {
        font-size: 16px;
      }
    }
  }

  .nav {
    height: 180px;
    gap: 30px;

    a {
      font-size: 16px;
    }
  }

  .scrollText span {
    font-size: 120px;
  }
}

@media (max-width: 768px) {
  .contactBox {
    width: 100%;
    padding: 15px;

    .letTalk {
      width: 120px;
      height: 120px;
    }

    .email {
      font-size: 20px;
    }

    .socialLinks {
      gap: 10px;

      div {
        gap: 4px;
      }

      a {
        font-size: 14px;
      }
    }
  }

  .nav {
    height: 150px;
    gap: 20px;

    a {
      font-size: 14px;
    }
  }

  .scrollText span {
    font-size: 100px;
  }
}

@media (max-width: 480px) {
  .contactBox {
    padding: 10px;

    .letTalk {
      width: 100px;
      height: 100px;
    }

    .email {
      font-size: 18px;
    }

    .socialLinks {
      gap: 8px;

      div {
        gap: 4px;
      }

      a {
        font-size: 12px;
      }
    }
  }

  .nav {
    height: 120px;
    gap: 15px;

    a {
      font-size: 12px;
    }
  }

  .scrollText span {
    font-size: 80px;
  }
}
