.container {
  height: 300vh;
  position: relative;

  .sticky {
    position: sticky;
    top: 0px;
    height: 100vh;
    background-color: #001b19;
    overflow: hidden;
  }
  .el {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .imageContainer {
      width: 23vw;
      height: 25vh;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &:nth-of-type(2) {
      .imageContainer {
        top: -30vh;
        left: 6vw;
        width: 35vw;
        height: 30vh;
      }
    }
    &:nth-of-type(3) {
      .imageContainer {
        top: -10vh;
        left: -25vw;
        width: 20vw;
        height: 45vh;
      }
    }
    &:nth-of-type(4) {
      .imageContainer {
        left: 27.5vw;
        width: 25vw;
        height: 25vh;
      }
    }
    &:nth-of-type(5) {
      .imageContainer {
        top: 27.5vh;
        left: 5vw;
        width: 20vw;
        height: 25vh;
      }
    }
    &:nth-of-type(6) {
      .imageContainer {
        top: 27.5vh;
        left: -22.5vw;
        width: 30vw;
        height: 25vh;
      }
    }
    &:nth-of-type(7) {
      .imageContainer {
        top: 22.5vh;

        left: 25vw;

        width: 15vw;

        height: 15vh;
      }
    }
  }
}
