.introduction {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  background-color: #141516;
  svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% + 300px);
    path {
      fill: #141516;
    }
  }
  p {
    display: flex;
    color: #afa18f;
    font-size: 42px;
    align-items: center;
    position: absolute;
    z-index: 1;
    // span {
    //   display: block;
    //   width: 10px;
    //   height: 10px;
    //   background-color: white;
    //   border-radius: 50%;
    //   margin-right: 10px;
    // }
  }
}
.fullStop {
  color: #00a699; // Green color for the full stop
  font-size: inherit; // Match the font size of the greeting text
}
