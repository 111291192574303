@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");

body {
  background-color: #001b19;
  margin: 0px;
  font-family: "Space Grotesk";

  margin-bottom: 0vh;
}
.ios-specific-class {
  /* Example styles specific to iOS devices */
  height: 75%;
  align-items: center;
  width: 75%;
  object-fit: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
