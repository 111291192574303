.container {
  height: 250vh;
  position: relative;
  bottom: 0vh;

  .sticky {
    position: sticky;
    top: 0px;
    height: 100vh;
    background-color: #001b19;
    overflow: hidden;
  }

  .body1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #afa18f;
    position: absolute;
    font-size: 36px;
    font-weight: 700;
    line-height: 56px;
    cursor: default;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1240px;
      width: 100%;

      padding: 20px;

      display: flex;

      height: 657px;

      .text {
        display: flex;

        max-width: 50%; /* Ensure the text takes up 50% of the available width */
        flex-direction: column;
      }

      .p1 {
        color: #4e525a;
      }
      p {
        width: 472px;

        padding: 20px;
        text-align: left;
      }
      img {
        flex: 1;
        max-width: 50%; /* Ensure the image takes up 50% of the available width */
        width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 768px) {
    .body1 {
      font-size: 24px;
      line-height: 36px;

      div {
        flex-direction: column;
        text-align: center;
        padding: 20px;

        .text,
        img {
          max-width: 100%;
        }

        .text {
          padding-bottom: 10px; /* Add space between the text and the image */
        }
      }
    }
  }

  @media (max-width: 480px) {
    .body1 {
      font-size: 18px;
      line-height: 28px;

      div {
        p {
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
}
